@use 'sass:math';
@import "vendor-prefixes";
@import "variables";

.flex-fill {
    @include display-flex();
    @include align-items(stretch);
    @include align-content(stretch);
}

.flex-wrap {
    @include flex-wrap(wrap);
}

.row-container {
    overflow: hidden;
}

.row-flex {
    @include display-flex();
    @include flex-wrap(wrap);
    @include align-items(stretch);
    margin: 0 -15px;
    margin: 0 calc(var(--flex-gutter) * -0.5);
}

@each $spacing-name, $spacing-value in $spacing {
    .row-flex-gutter#{if($spacing-name == default, ',.row-flex-gutter', '')}-#{$spacing-name} {
        --flex-gutter: #{$spacing-value * 2};
    }
}

.row-flex-centered {
    @include justify-content(center);
}

.row-flex-paragraph {
    margin-bottom: 15px;
}

.col-flex {
    padding: 0 15px;
    padding: 0 calc(var(--flex-gutter) * 0.5);
}

.col-flex-contain {
    position: relative;
}

.col-flex-ap {
    &:nth-child(n+2) { padding-left: 15px; }
    &:nth-last-child(n+2) { padding-right: 15px; }
}

.col-flex-wide {
    @include flex(1 0 0);
}

.col-flex-wide-100 {
    @include flex(100 0 0);
}

.col-flex-stretch {
    @include display-flex();
    @include flex-direction(column);
    @include align-content(stretch);
    @include align-items(stretch);
}

.col-flex-centered {
    @include display-flex();
    @include align-items(center);
    @include justify-content(center);
    @include align-content(center);
}

.col-flex-centered-vertical {
    @include display-flex();
    @include align-items(center);
    @include align-content(center);
}

.col-flex-content-centered {
    @include display-flex();
    @include justify-content(space-around);
    @include flex-direction(column);
}

.col-flex-content-bottom {
    @include display-flex();
    @include justify-content(flex-end);
    @include flex-direction(column);
}

.col-flex-align-bottom {
    @include align-self(end);
}

@for $col from 1 through $columns {
    .col-flex-#{$col} {
        width: 100% * math.div($col, $columns);
    }
    .col-flex-max-#{$col} {
        max-width: 100% * math.div($col, $columns);
    }
}

@each $size-name, $size in $sizes {
    @media (min-width: $size) {
        @for $col from 1 through $columns {
            .col-flex-#{$size-name}-#{$col} {
                width: 100% * math.div($col, $columns);
            }
            .col-flex-#{$size-name}-max-#{$col} {
                max-width: 100% * math.div($col, $columns);
            }
        }
        .col-flex-#{$size-name}-auto {
            width: auto;
        }
        .col-flex-#{$size-name}-max-auto {
            max-width: none;
        }
        .col-flex-#{$size-name}-wide {
            @include flex(1 0 0);
        }
        .col-flex-#{$size-name}-wide-100 {
            @include flex(100 0 0);
        }
    }
}

@each $name, $prop in $justify-content {
    .justify-#{$name} {
        justify-content: $prop;
    }
}

@each $name, $prop in $justify-items {
    .justify-items-#{$name} {
        justify-items: $prop;
    }
}

@each $name, $prop in $justify-self {
    .justify-self-#{$name} {
        justify-self: $prop;
    }
}

@each $name, $prop in $align-content {
    .content-#{$name} {
        align-content: $prop;
    }
}

@each $name, $prop in $align-items {
    .items-#{$name} {
        align-items: $prop;
    }
}
@each $name, $prop in $align-self {
    .self-#{$name} {
        align-self: $prop;
    }
}

@use 'sass:math';
@import "~sass-math-pow/sass/index";

$colors: (
    'red': #cc122a,
    'green': #85c35b,
    'blue': #00adef,
    'blue-2': #48b0f7,
    'darkish-blue': #0072bc,
    'dark-blue': #00428d,
    'yellow': #fda816,
    'orange': #ea5a02,
    'pink': #d81470,
    'beige': #d8ba89,
    'sandy': #ffe0ab,
    'gold': #daa520,
    'silver': #c4cace,
    'bronze': #a57164,
    'white': #fff,
    'near-white': #f8f8f8,
    'lighter-grey': #eee,
    'lighter-gray': #eee,
    'light-grey': #ccc,
    'light-gray': #ccc,
    'grey': #999,
    'gray': #999,
    'dark-grey': #555,
    'dark-gray': #555,
    'darker-grey': #222,
    'darker-gray': #222,
    'near-black': #080808,
    'black': #000
);

$default-reseller-colors: (
    reseller-primary: #0dcebc,
    reseller-secondary: #2a3542,
    reseller-secondary-alt: #35404d,
    reseller-accent: #f0f1f6,
    reseller-highlight: map-get($colors, 'blue-2')
);

$default-contrast: (
    dark: #000,
    light: #fff,
    threshold: 78%
);

@function color($color, $fallback: null) {
    @if(variable-exists(reseller-colors) and map-has-key($reseller-colors, $color)) {
        @return map-get($reseller-colors, $color);
    }
    @if(map-has-key($default-reseller-colors, $color)) {
        @return map-get($default-reseller-colors, $color);
    }
    @if(map-has-key($colors, $color)) {
        @return map-get($colors, $color);
    }
    @return $fallback;
};

@function get-brightness($color, $normalized: true) {
    $returnValue: (red($color) * 0.299 + green($color) * 0.587 + blue($color) * 0.114);
    @if ($normalized) {
        @return math.div($returnValue, 255) * 100%;
    }
    @return $returnValue;
}

@function luminosity($color) {
    @return
        0.2126 * pow(math.div(red($color), 255), 2.2) +
        0.7152 * pow(math.div(green($color), 255), 2.2) +
        0.0722 * pow(math.div(blue($color), 255), 2.2);
}

@function color-difference($color1, $color2) {
    @return abs(red($color1) - red($color2)) + abs(green($color1) - green($color2)) + abs(blue($color1) - blue($color2));
}

@function brightness-difference($color1, $color2) {
    @return abs(get-brightness($color1, false) - get-brightness($color2, false));
}

@function luminosity-contrast($color1, $color2) {
    $luminosity1: luminosity($color1);
    $luminosity2: luminosity($color2);

    @if ($luminosity1 > $luminosity2) {
        @return math.div(($luminosity1 + 0.05), ($luminosity2 + 0.05));
    }
    @return math.div(($luminosity2 + 0.05), ($luminosity1 + 0.05));
}

@function get-contrast-score($color1, $color2) {
    @return min(1, math.div(color-difference($color1, $color2), 500)) +
            min(1, math.div(brightness-difference($color1, $color2), 125)) +
            min(1, math.div(luminosity-contrast($color1, $color2), 5));
}

@function requires-contrast-color(
    $color,
    $dark: #000,
    $light: #fff,
    $threshold: 78%) {
    @if($color == "dark") {
        @return true;
    }
    @if($color == "light") {
        @return false;
    }
    @if ($threshold == -1) {
        @return get-contrast-score($color, $light) < get-contrast-score($color, $dark);
    }
    $brightness: get-brightness($color);
    @if($threshold > 0) {
        @return $brightness < $threshold;
    }
    $brightness-dark: get-brightness($dark);
    $brightness-light: get-brightness($light);
    @if($brightness-dark > $brightness-light) {
        $temp-brightness: $brightness-dark;
        $brightness-dark: $brightness-light;
        $brightness-light: $temp-brightness;
    }
    @return abs($brightness - $brightness-light) > abs($brightness - $brightness-dark);
}

@function contrast-color(
    $color,
    $dark: #000,
    $light: #fff,
    $threshold: 78%) {
    $brightness-dark: get-brightness($dark);
    $brightness-light: get-brightness($light);
    @if($brightness-dark > $brightness-light) {
        $temp-color: $dark;
        $dark: $light;
        $light: $temp-color;
    }
    @return if(requires-contrast-color($color, $dark, $light, $threshold), $light, $dark);
}

@function hover-color(
    $color,
    $percentage: 5%,
    $dark: #000,
    $light: #fff,
    $threshold: 78%) {
    @if($color == "dark") {
        @return if($percentage < 0%, darken($dark, abs($percentage)), lighten($dark, $percentage));
    }
    @if($color == "light") {
        @return if($percentage < 0%, lighten($light, abs($percentage)), darken($light, $percentage));
    }
    $brightness: get-brightness($color);
    $brightness-dark: get-brightness($dark);
    $brightness-light: get-brightness($light);
    @if(-$brightness-dark > -$brightness-light) {
        $temp-color: $dark;
        $dark: $light;
        $light: $temp-color;
    }
    @if($threshold > 0) {
        @return if($brightness > $threshold, darken($color, $percentage), lighten($color, $percentage));
    }
    @return if(
        abs($brightness - $brightness-light) > abs($brightness - $brightness-dark),
        if($percentage < 0%, lighten($color, $percentage), darken($color, $percentage)),
        if($percentage < 0%, darken($color, $percentage), lighten($color, $percentage))
    );
}

@function contrast-color-auto(
    $color,
    $dark: map-get($default-contrast, dark),
    $light: map-get($default-contrast, light)) {
        @return contrast-color($color, $dark, $light, -1);
}

@function nearest-absolute-color(
    $color,
    $dark: map-get($default-contrast, dark),
    $light: map-get($default-contrast, light),
    $threshold: map-get($default-contrast, threshold)) {
    @return contrast-color(contrast-color($color, $dark, $light, $threshold), $dark, $light, $threshold);
}

@import "../common/colors";

.circle-link {
    border: none;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 6px;
    background-color: color('grey');
    color: contrast-color(color('grey'));
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

    svg {
        width: auto;
        height: auto;
        fill: currentColor;
        display: block;
        margin: 0 auto;
    }

    @each $color-name, $color-value in $colors {
        &.circle-link-#{'' + $color-name} {
            background-color: $color-value;
            color: contrast-color($color-value);
        }
    }
}

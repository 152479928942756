@import './settings';

$columns: 12;

$block-sizes: (
    8,
    10,
    12,
);

$sizes: (
    xxs: 480px,
    xs: 576px,
    sm: 768px,
    im: 850px,
    md: 992px,
    ml: 1100px,
    lg: 1200px,
    xl: 1400px,
    xxl: 1600px,
    hd: 1920px,
    uhd: 2560px,
    4k: 3840px,
    8k: 7680px
);

$spacing: (
    none: 0px,
    minimal: 5px,
    small: 10px,
    default: 15px,
    medium: 20px,
    large: 30px,
    xlarge: 45px,
    xxlarge: 60px
);

$spacing-types: (
    pd: padding,
    mg: margin
);

$spacing-directions: (
    up: (top),
    down: (bottom),
    left: (left),
    right: (right),
    x: (left, right),
    y: (top, bottom)
);

$border-styles: (
    solid,
    dotted,
    dashed,
    double,
    groove,
    ridge,
    inset,
    outset
);

$aspect-ratios: (
    (1, 1),
    (4, 3),
    (16, 9),
    (16, 10),
    (21, 9),
    (32, 9),
    (69, 25),
    (18, 5),
    (4, 1),
);

$border-radius: 4px;

$cursors: (
    auto,
    default,
    none,
    context-menu,
    help,
    pointer,
    progress,
    wait,
    cell,
    crosshair,
    text,
    vertical-text,
    alias,
    copy,
    move,
    no-drop,
    not-allowed,
    all-scroll,
    col-resize,
    row-resize,
    n-resize,
    e-resize,
    s-resize,
    w-resize,
    ne-resize,
    nw-resize,
    se-resize,
    sw-resize,
    ew-resize,
    ns-resize,
    nesw-resize,
    nwse-resize,
    zoom-in,
    zoom-out,
    grab,
    grabbing
);

$text-size: (
    min: 0.5,
    max: 2,
    step: 0.05,
);

@function size($size, $max: false) {
    @if(map-has-key($sizes, $size)) {
        @return map-get($sizes, $size) - if($max, 1px, 0);
    }
    @return null;
};

@function spacing($size) {
    @if(map-has-key($spacing, $size)) {
        @return map-get($spacing, $size);
    }
    @return null;
}

$justify-content: (
    start: flex-start,
    end: flex-end,
    center: center,
    between: space-between,
    around: space-around,
    evenly: space-evenly,
);

$justify-items: (
    start: start,
    end: end,
    center: center,
    stretch: stretch,
);

$justify-self: (
    auto: auto,
    start: start,
    end: end,
    center: center,
    stretch: stretch,
);

$align-content: (
    start: flex-start,
    end: flex-end,
    center: center,
    between: space-between,
    around: space-around,
    evenly: space-evenly,
    baseline: baseline,
);

$align-items: (
    start: flex-start,
    end: flex-end,
    center: center,
    stretch: stretch,
    baseline: baseline,
);

$align-self: (
    auto: auto,
    start: flex-start,
    end: flex-end,
    center: center,
    stretch: stretch,
    baseline: baseline,
);

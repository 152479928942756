@import './layers';

@import '../common/root';
@import "../pages/settings";

@import "../common/svg";

/*==========  end responsive  ==========*/

@import "../common/bootstrap";

/*==========  responsive  ==========*/

/* Large Devices, Wide Screens */
@media (min-width : 1200px) {
    #page { padding-top: 61px; padding-bottom: 60px; }

    #page .section:nth-child(2) .column_1 {
        padding-top: 40px;
    }

    #page .index_block .text {
        min-height: 150px;
        padding-bottom: 10px;
    }

    .column_2a {
        margin-top: 61px;
        padding-bottom: 30px;
    }

    #page .carousel_blocks h4.carousel-lay-out-header-1 {
        font-size: 240%;
    }
    #page .formblock_info h3 {
        font-size: 240%;
    }
    #page .index_block_header h3 {
        font-size: 120%;
    }
    #page .carousel_blocks h4 {
        font-size: 120%;
    }
}

@media (max-width : 1200px) {
    #page { padding-top: 61px; padding-bottom: 60px; }

    #page .section:nth-child(2) .column_1 {
        padding-top: 40px;
    }

    #page .index_block .text {
        min-height: 150px;
        padding-bottom: 10px;
    }

    .column_2a {
        margin-top: 61px;
        padding-bottom: 30px;
    }

    #page .carousel_blocks h4.carousel-lay-out-header-1 {
        font-size: 200%;
    }
    #page .formblock_info h3 {
        font-size: 200%;
    }
    #page .index_block_header h3 {
        font-size: 100%;
    }
    #page .carousel_blocks h4 {
        font-size: 100%;
    }
}

/* Medium Devices, Desktops */
@media (max-width : 992px) {
    #page { padding-top: 91px; padding-bottom: 60px; }

    #page .section:nth-child(2) .column_1 {
        padding-top: 40px;
    }

    #page .index_block .text {
        min-height: inherit;
        padding-bottom: 10px;
    }

    .column_2a {
        margin-top: 0;
        padding-bottom: 30px;
    }

    #page .carousel_blocks h4.carousel-lay-out-header-1 {
        font-size: 240%;
    }
    #page .formblock_info h3 {
        font-size: 240%;
    }
    #page .index_block_header h3 {
        font-size: 120%;
    }
    #page .carousel_blocks h4 {
        font-size: 120%;
    }
}

/* Small Devices, Tablets */
@media (max-width : 768px) {
    #page { padding-top: 61px; padding-bottom: 60px; }

    #page .section:nth-child(2) .column_1 {
        padding-top: 40px;
    }

    #page .index_block .text {
        min-height: inherit;
        padding-bottom: 10px;
    }

    .column_2a {
        margin-top: 0;
        padding-bottom: 30px;
    }

    #page .carousel_blocks h4.carousel-lay-out-header-1 {
        font-size: 180%;
    }
    #page .formblock_info h3 {
        font-size: 240%;
    }
    #page .index_block_header h3 {
        font-size: 120%;
    }
    #page .carousel_blocks h4 {
        font-size: 120%;
    }
}

/* Extra Small Devices, Phones */
@media (max-width : 480px) {
    #page { padding-top: 61px; padding-bottom: 60px; }

    #page .section:nth-child(2) .column_1 {
        padding-top: 40px;
    }

    #page .index_block .text {
        min-height: inherit;
        padding-bottom: 10px;
    }

    .column_2a {
        margin-top: 0;
        padding-bottom: 30px;
    }

    #page .carousel_blocks h4.carousel-lay-out-header-1 {
        font-size: 160%;
    }
    #page .formblock_info h3 {
        font-size: 160%;
    }
    #page .index_block_header h3 {
        font-size: 100%;
    }
    #page .carousel_blocks h4 {
        font-size: 120%;
    }
}

/* Custom, iPhone Retina */
@media (max-width : 320px) {
    #page { padding-top: 61px; padding-bottom: 60px; }

    #page .section:nth-child(2) .column_1 {
        padding-top: 40px;
    }

    #page .index_block .text {
        min-height: inherit;
        padding-bottom: 10px;
    }

    .column_2a {
        margin-top: 0;
        padding-bottom: 30px;
    }

    #page .carousel_blocks h4.carousel-lay-out-header-1 {
        font-size: 160%;
    }
    #page .formblock_info h3 {
        font-size: 160%;
    }
    #page .index_block_header h3 {
        font-size: 100%;
    }
    #page .carousel_blocks h4 {
        font-size: 120%;
    }
}

#page {
    padding-top: 50px;
}

.website_status { position: relative; height: 30px; padding: 5px 15px; text-align: center; background-color: #000; color: #fff; }

/*==========  begin like / share  ==========*/

/* This element holds injected scripts inside iframes that in some cases may stretch layouts. So, we're just hiding it. */
.fb_wrap { width: 450px !important; }
.fb_iframe_widget span {
    display: table-caption !important;
}
.fb_iframe_widget {
    display: block;
    position: relative;
}

ul.share_buttons {
    float: left;
    padding-left: 0;
    margin-bottom: 0;
}
.share_buttons li {
    float: left;
    list-style: none;
    margin-right: 10px;
}

.only_share_buttons { padding-bottom: 15px; float: left; }
.only_share_buttons a { text-decoration: none; }
.only_share_buttons a:hover { text-decoration: none; }

/*==========  end like / share  ==========*/


/*==========  custom carousel  ==========*/
#carousel_blocks_products .carousel-control.left, .carousel-control.right {
    background-image: none;
}
#carousel_blocks_products .carousel-control {
    top: inherit;
}

#carousel_blocks_products .next_products, #carousel_blocks_products .prev_products {
    cursor: pointer;
    top: 50%;
    margin-top: -30px;
    opacity: 0;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    z-index: 200;
    font-family: 'entypo';
    right: -50px;
    display: block;
    width: 48px;
    height: 50px;
    text-decoration: none;
    font-size: 14px;
    line-height: 50px;
    color: #fff;
    background: #000;
    text-align: center;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    -webkit-box-shadow: inset 0 4px 5px -2px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: inset 0 4px 5px -2px rgba(0, 0, 0, 0.20);
    box-shadow: inset 0 4px 5px -2px rgba(0, 0, 0, 0.20);
    list-style: none;
}
#carousel_blocks_products .prev_products {
    left: -50px;
    right: auto;
}
#carousel_blocks_products .prev_products:hover,
#carousel_blocks_products .next_products:hover { opacity: .5 }
#carousel_blocks_products:hover .next_products {
    opacity: .5;
    right: 10px;
}
#carousel_blocks_products:hover .prev_products {
    opacity: .5;
    left: 10px;
}
#carousel_blocks_products .next_products i {
    /*position: relative;*/
    left: 16px;
    *top:18px;
    top:18px;
}
#carousel_blocks_products .prev_products i {
    /*position: relative;*/
    left: 16px;
    *top:18px;
    top:18px;
}
/*==========  end custom carousel  ==========*/


/*==========  custom upload form button  ==========*/
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
/* UPLOAD ELEMENT FORM */
.uploadDiv {
    border-top: 1px solid #ccc;
    /*margin-top: 12px;*/
}
form p.upload-info { border-bottom: 1px dotted #f1f1f1; padding: 4px 8px; background-color: #fff; }
/*==========  end custom upload form button  ==========*/

/*==========  wizard  ==========*/

/*
    Common
*/

.wizard,
.tabcontrol
{
    display: block;
    width: 100%;
    overflow: hidden;
}

.wizard a,
.tabcontrol a
{
    outline: 0;
}

.wizard ul,
.tabcontrol ul
{
    list-style: none !important;
    padding: 0;
    margin: 0;
}

.wizard ul > li,
.tabcontrol ul > li
{
    display: block;
    padding: 0;
}

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info
{
    position: absolute;
    left: -999em;
}

.wizard > .content > .title,
.tabcontrol > .content > .title
{
    position: absolute;
    left: -999em;
}

/* captrcha image */
.img-cap-thumbnail img {
    display: inline-block;
    height: auto;
    max-width: 100%;
    padding: 4px;
    line-height: 1.428571429;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

/*
    Wizard
*/

.wizard > .steps
{
    position: relative;
    display: block;
    width: 100%;
}

.wizard.vertical > .steps
{
    display: inline;
    float: left;
    width: 30%;
}

.wizard > .steps .number
{
    font-size: 1.429em;
}

.wizard > .steps > ul > li
{
    width: 25%;
}

.wizard > .steps > ul > li,
.wizard > .actions > ul > li
{
    float: left;
}

.wizard.vertical > .steps > ul > li
{
    float: none;
    width: 100%;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active
{
    display: block;
    width: auto;
    margin: 0 0.5em 0.5em 0;
    padding: 1em 1em;
    text-decoration: none;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active
{
    background: #eee;
    color: #aaa;
    cursor: default;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active
{
    background: #2184be;
    color: #fff;
    cursor: default;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active
{
    background: #9dc8e2;
    color: #fff;
}

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active
{
    background: #ff3111;
    color: #fff;
}

.wizard > .content
{
    background: #eee;
    display: block;
    margin: 0.5em 0;
    min-height: 8em;
    overflow: hidden;
    position: relative;
    width: auto;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard.vertical > .content
{
    display: inline;
    float: left;
    margin: 0 2.5% 0.5em 2.5%;
    width: 65%;
}

.wizard > .content > .body
{
    float: left;
    /*position: absolute;*/
    width: 95%;
    height: 95%;
    padding: 2.5%;
}

.wizard > .content > .body ul
{
    list-style: disc !important;
}

.wizard > .content > .body ul > li
{
    display: list-item;
}

.wizard > .content > .body > iframe
{
    border: 0 none;
    width: 100%;
    height: 100%;
}

.wizard > .content > .body input
{
    display: block;
    border: 1px solid #ccc;
}

.wizard > .content > .body input[type="checkbox"]
{
    display: inline-block;
}

.wizard > .content > .body input.error
{
    background: rgb(251, 227, 228);
    border: 1px solid #fbc2c4;
    color: #8a1f11;
}

.wizard > .content > .body label
{
    display: inline-block;
    margin-bottom: 0.5em;
}

.wizard > .content > .body label.error
{
    color: #8a1f11;
    display: inline-block;
    margin-left: 1.5em;
}

.wizard > .actions
{
    position: relative;
    display: block;
    text-align: center;
    width: 100%;
}

.wizard.vertical > .actions
{
    display: inline;
    float: right;
    margin: 0 2.5%;
    width: 95%;
    text-align: right !important;
}

.wizard > .actions > ul
{
    display: inline-block;
    text-align: center;
}

.wizard > .actions > ul > li
{
    margin: 0 0.2em;
}

.wizard.vertical > .actions > ul > li
{
    margin: 0 0 0 0.3em;
}

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active
{
    background: #2184be;
    color: #fff;
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active
{
    background: #eee;
    color: #aaa;
}

/*
    Tabcontrol
*/

.tabcontrol > .steps {
    position: relative;
    display: block;
    width: 100%;
}

.tabcontrol > .steps > ul {
    position: relative;
    margin: 6px 0 0 0;
    top: 1px;
    z-index: 1;
}

.tabcontrol > .steps > ul > li {
    float: left;
    margin: 5px 2px 0 0;
    padding: 1px;

    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tabcontrol > .steps > ul > li:hover {
    background: #edecec;
    border: 1px solid #bbb;
    padding: 0;
}

.tabcontrol > .steps > ul > li.current {
    background: #fff;
    border: 1px solid #bbb;
    border-bottom: 0 none;
    padding: 0 0 1px 0;
    margin-top: 0;
}

.tabcontrol > .steps > ul > li > a {
    color: #5f5f5f;
    display: inline-block;
    border: 0 none;
    margin: 0;
    padding: 10px 30px;
    text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover { text-decoration: none; }

.tabcontrol > .steps > ul > li.current > a { padding: 15px 30px 10px 30px; }

.tabcontrol > .content {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 35em;
    overflow: hidden;
    border-top: 1px solid #bbb;
    padding-top: 20px;
}

.tabcontrol > .content > .body {
    float: left;
    position: absolute;
    width: 95%;
    height: 95%;
    padding: 2.5%;
}

.tabcontrol > .content > .body ul { list-style: disc !important; }

.tabcontrol > .content > .body ul > li { display: list-item; }


/*==========  navbar  ==========*/

/*==========  end navbar  ==========*/


/*==========  GENERAL  ==========*/
@media (max-width: 767px) {
    body.index {
        height: 100%;
        margin: 0;
    }
}

@media (min-width: 768px) {
    body.index {
        height: 100%;
        margin: 0;
    }
}

@import "../elements/overlay";

.lang_selector {
    line-height: 50px;
}

.lang_selector a {
    display: block;
    text-align: center;
    width: 30px;
    float: left;
}

#slideshow .wrapper { position: relative; z-index: 99; }

#accordion_faq a { display: block; }

#custom_form .well { margin-bottom: 5px; background: none transparent; border: none; box-shadow: none; }
#custom_form_footer .well { margin-bottom: 5px; background: none transparent; border: none; box-shadow: none; }
body #page .formblock_main h1 { text-transform: none; margin: 0; padding: 0; }

ul#sub_pages li { list-style: none; }
ul#sub_pages { padding-left: 0; }

ul#sub_pages li ul#sub_sub_pages { padding-left: 15px; margin-top: 6px; margin-bottom: 6px; }

a.blog_hideme i { font-size: 12px; vertical-align: top; }

body {
    background: none;
    font-family: Arial,"Lucida Grande",sans-serif;
}

header { margin-top: 0; padding-bottom: 15px; box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2); }

#logo { padding: 15px 0; }

body .navbar { margin-bottom: 0; }

header .navbar-nav > li > a {
    padding-bottom: 5px;
    padding-top: 5px;
}

.navbar-fixed-top, .navbar-fixed-bottom {
    left: 0;
    position: static;
    right: 0;
    z-index: 1030;
}
header .multi-button a.ddown {
    background-color: transparent;
    padding-left: 5px;
    position: absolute;
    right: -15px;
    z-index: 9;
}
#bs-example-navbar-collapse-1.navbar-collapse { max-height: inherit; }

.body_overflow { overflow: hidden; }

table.custom_table {
    tr td { width: 33%; }
}

p { padding-right: 30px; }

textarea { resize: vertical; }

.message_ajax_cart,
.message_ajax_cart_add,
.message_ajax_mail_success,
.message_ajax_mail_error,
.message_ajax_gb_error,
.message_ajax_gb_empty_error,
.message_ajax_stock_error,
.msp_error,
.coupon_error,
.coupon_success,
.var_json_success,
.var_json_error { display: none; }

.progress { height: 20px; text-align: center; margin-left: auto; margin-right: auto; height: 100%; position: absolute; overflow: hidden; }
.loading_overlay { position: fixed; height: 100%; width: 100%; background-color: #999; opacity: 0.6; top: 0; left: 0; z-index: 9999999; text-align: center; overflow: hidden; }

hr.dash {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #DEDEDE -moz-use-text-color -moz-use-text-color;
    border-image: none;
    border-right: 0 none;
    border-style: dashed none none;
    border-width: 1px 0 0;
    margin-bottom: 20px;
    margin-top: 20px;
}
/*==========  end GENERAL  ==========*/


/*==========  webshop  ==========*/
#cartbox a {
    color: #999999;
    line-height: 20px;
    padding-top: 15px;
}

#cartbox a:hover {
    color: #fff;
}

#product_reviews { display: none; }
#product_reviews .review_date { color: #ccc; }
#product_reviews .hide_reviews { text-align: right; }
#product_reviews .review_avatar { margin-top: 5px; }

header .shopping_cart {
    text-align: right;
}

.old_price { text-decoration: line-through; }
.old_price:hover { text-decoration: line-through; }

.producttitle2 { line-height: 40px; font-size: 16px; }
.filter_bar { background-color:#f1f1f1; padding: 10px; border-top: 2px solid #CCCCCC; }

.add_high_amount {
    background: none repeat scroll 0 0 #f1f1f1;
    border: 1px solid #cccccc;
    display: none;
    height: 110px;
    padding: 3px 5px;
    position: absolute;
    width: 200px;
    z-index: 1;
    font-size: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.add_high_amount_help {
    padding: 5px 6px;
    font-size: 11px;

}
.add_high_amount_help input {
    margin-top: 7px;
    height: 26px;
    padding: 4px 8px;
    margin-bottom: 3px;
}

.price-hidden {
    display:none;
}

.spec-filter-hidden {
    display:none;
}
/*==========  end webshop  ==========*/


/*BREADCRUMB*/
#bcrumb {
    font-size: 12px;
    border-bottom: 2px solid #ccc;
    line-height: 20px;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 20px;
}

#bcrumb ul {
    padding-left: 0;
    margin: 0;
}

#bcrumb ul li {
    float: left;
    list-style: none;
}

#bcrumb ul li a {
    text-decoration: none;
    color: #2E342F;
}

#bcrumb ul li a:hover {
    text-decoration: underline;
    color: #2E342F;
}
/*END BREADCRUMB*/

/*MAIN NAV*/
a.navbar-btn { margin-top: 0; }
/*.in li a.navbar-btn { margin-top: 8px; }*/

.nav-top-collapse { background: none; }
.in .nav-top-collapse {
    background: #c9cacc; /* Old browsers */
    background: -moz-linear-gradient(top, #c9cacc 0%, #d9dadc 100%, #7db9e8 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#c9cacc), color-stop(100%,#d9dadc), color-stop(100%,#7db9e8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #c9cacc 0%,#d9dadc 100%,#7db9e8 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #c9cacc 0%,#d9dadc 100%,#7db9e8 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #c9cacc 0%,#d9dadc 100%,#7db9e8 100%); /* IE10+ */
    background: linear-gradient(to bottom, #c9cacc 0%,#d9dadc 100%,#7db9e8 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c9cacc', endColorstr='#7db9e8',GradientType=0 ); /* IE6 */
    margin-top: 2px;
    padding: 0 10px;
}

.collapsing .nav-top-collapse {
    background: #c9cacc; /* Old browsers */
    background: -moz-linear-gradient(top, #c9cacc 0%, #d9dadc 100%, #7db9e8 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#c9cacc), color-stop(100%,#d9dadc), color-stop(100%,#7db9e8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #c9cacc 0%,#d9dadc 100%,#7db9e8 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #c9cacc 0%,#d9dadc 100%,#7db9e8 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #c9cacc 0%,#d9dadc 100%,#7db9e8 100%); /* IE10+ */
    background: linear-gradient(to bottom, #c9cacc 0%,#d9dadc 100%,#7db9e8 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c9cacc', endColorstr='#7db9e8',GradientType=0 ); /* IE6 */
    margin-top: 2px;
}

a.navbar-btn { background-color: transparent; padding: 8px; }
.nav-top-collapse li { color: #333; }
.nav-top-collapse li a { color: #333; margin: 0; border-bottom: 1px solid #dedede; border-top: 1px solid #dedede; }
.nav-top-collapse li a:hover { background-color: #dedede; }
.nav-top-collapse li:hover { background-color: #dedede; }


.bookmark_links a { text-decoration: none; }


.min_button { color: #fff; }
.plus_button { color: #fff; }

.min_button i,
.plus_button i {
    font-size: 12px;
    margin-top: 3px;
    padding: 0 2px;
    vertical-align: top;
}

/* main navigation */
#main_nav {
    margin-top: 20px;

    #nav ul {
        margin: 0;
        padding-left: 0;
        list-style: none outside none;

        li {
            float: left;
            cursor: pointer;
            display: block;
            float: left;
            height: 30px;
            margin: 0;
            padding: 0;
            text-align: left;
        }
    }
}

#main_nav #nav li a {
    display: block;
    height: 100%;
    text-decoration: none;
}
#main_nav #nav li a span {
    display: block;
    padding: 8px 15px;
}
#main_nav #nav .nav-main-collapse {
    position: absolute;
    right: 19px;
    z-index: 9999;
    margin-top: 44px;
    margin-right: 10px;
}
#main_nav #nav .nav-main-collapse ul {
    background-clip: padding-box;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px 6px 6px 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    padding: 0 5px 5px;
}
#main_nav #nav .nav-main-collapse ul li {
    float: none;
    border: none;
    background-color: #fff;
}
#main_nav #nav .nav-main-collapse ul li a {
    display: block;
    padding: 0;
    color: #000;
    background-color: #fff;
}
#main_nav {
    border-bottom: 1px solid #ccc;
    font-family: Pathway Gothic One, sans-serif;
    font-size: 0.9rem;
}
#main_nav #nav li a {
    color: #2424BF; text-decoration: none; background-color: #FFFFFF;
}

.navbar-fixed-top {
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2);
}
ul.nav li.dropdown > ul.dropdown-menu li > a:hover { background-color: #f1f1f1; background-image: none; color: #000; }
ul.nav li.dropdown div > ul.dropdown-menu li > a:hover { background-color: #f1f1f1; background-image: none; color: #000; }

#main_nav #nav ul.dropdown-menu {
    background-color: #FFFFFF;
}

.panel-heading a { color: #828072; }
.panel-heading {
    background-color: #fafafa;
}

.filter_head { font-family: 'Pathway Gothic One', sans-serif; padding-bottom: 8px; }
.filters .box_left { background-color: #fff; }

header .multi-button a.main {
    padding-right: 30px;
}
header .multi-button a.ddown {
    position: absolute;
    right: -5px;
    padding-left: 5px;
    z-index: 9;
    background-color: transparent;
}
header .multi-button a.ddown:hover {
    background-color: inherit;
}
header .btn-group.open .dropdown-toggle {
    box-shadow: none;
}

.main_nav {
    font-family: 'Pathway Gothic One' ,sans-serif;

    ul {
        margin: 0;
        padding-left: 0;

        li {
            display: inline-block;
            font-size: 1.2em;
            font-weight: normal;
            line-height: 1.42857;
            margin-bottom: 0;
            padding: 0;
            text-align: left;
            vertical-align: middle;
            margin-right: 0;
            white-space: nowrap;

            a {
                display: block;
                text-decoration: none;
                padding: 3px 20px;
                cursor: pointer;
                color: #fff;

                &:hover {
                    color: #fff;
                }

                &.actief {
                    color: #fff;
                    cursor: pointer;
                }
            }

            &.dropdown {
                > ul.dropdown-menu,
                div > ul.dropdown-menu {
                    background: none #FFF;
                    border: 1px solid #dedede;
                    z-index: 999;

                    li {
                        border: none;
                        cursor: pointer;
                        background: none #FFF;
                        padding: 3px 5px;
                        min-width: 192px;
                        margin: 0;
                        height: auto;
                        display: block;
                        float: none;
                        white-space: nowrap;

                        &:hover {
                            background: none #dedede;
                        }

                        a {
                            color: #333;
                            border: none;
                            cursor: pointer;
                            background: none;
                            text-align: left;
                            display: block;
                            padding: 0 10px;
                        }
                    }
                }
            }
        }
    }
}

.navigation .navbar-default {
    background-color: transparent;
}
/*END MAIN MENU*/


/*HEADER*/
.price_index {
    padding: 22px 20px 0;
}

.price_index hr {
    margin: 10px 0;
}

.wide_grey {
    border-bottom: 2px solid #C1C0B9;
    line-height: 50px;
    margin-bottom: 20px;
    margin-top: 0;
    background-color: #fff;
}
/*END HEADER*/


/*SECTION*/
.bs-old-docs-white {
    border-bottom: none;
    border-top: none;
    color: #777777;
    margin-top: 0;
    padding: 20px 0;
    border-bottom: 1px solid #C1C0B9;
}

.bs-old-docs {
    background-color: #FAFAFA;
    border-bottom: 1px solid #E5E5E5;
    border-top: 1px solid #FFFFFF;
    color: #777777;
    padding: 15px 0;
    margin-top: 20px;
}

.bs-old-docs-grey {
    background-color: #EDEDED;
    border-bottom: none;
    border-top: none;
    color: #777777;
    margin-top: 0;
    padding: 20px 0;
}
/*END SECTION*/


a.collapsed { margin-bottom: 20px; }
a.bs_white.collapsed { margin-bottom: 0; }

.alert h4 { margin:0; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    font-family: 'Pathway Gothic One', sans-serif;
    font-weight: normal;
}

@media (max-width: 240px) {
    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
        font-size: 1rem;
    }
}

@media (max-width: 320px) {
    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
        font-size: 1rem;
    }
}

@media (min-width: 768px) {
    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
        font-size: 2rem;
    }
}

@media (min-width: 1024px) {
    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
        font-size: 2rem;
    }
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
    color: inherit;
    font-size: 2rem;
}

.my_color {  color: #48a8b4; }
.my_black_color {  color: #000; padding-right: 20px; }
.my_black_color_no_space {  color: #000; }
.my_black_color_block {  color: #000; font-weight: bold; display: block; line-height: 12px; }
.my_white_color_no_space {  color: #fff; }
.my_green_color_no_space {  color: #97c00e; }
.my_red_color_no_space {  color: #cc122a; }
.forgot_passw { line-height: 34px; }
.forgot_passw a:hover { text-decoration: none; }

footer.jumbotron.white {
    background-color: #fff;
}

footer.jumbotron {
    border-top: 1px solid #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
    padding: 10px;
}

footer.jumbotron a {
    color: #000;
    font-size: 12px;
    font-weight: bold;
}

/* DATES CONTAINER */
.datecontainerparent {
    margin-top: 5px;
    width: 54px;
}

.monthholder {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    border-bottom: 4px solid #bababa;
    border-left: 4px solid #bababa;
    border-right: 4px solid #bababa;
    color: #000;
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    width: 48px;
    font-weight: bold;
    font-family: 'Pathway Gothic One', sans-serif;
}

.monthsub {
    font-size: 11px;
    line-height: 11px;
    padding: 3px 0 2px;
}

.dateholder {
    background-color: #FFFFFF;
    border-top: 4px solid #bababa;
    border-left: 4px solid #bababa;
    border-right: 4px solid #bababa;
    border-radius: 10px 10px 0 0;
    color: #bababa;
    display: block;
    font-size: 12px;
    color: #000;
    font-weight: 300;
    padding: 0;
    text-align: center;
    width: 48px;
    line-height: 20px;
    font-weight: bold;
    font-family: 'Pathway Gothic One', sans-serif;
}

.datecontainer {
    font-size: 28px;
    line-height: 28px;
    padding-top: 3px;
}
/*END DATES*/

/* LNG SELECT */
.lng_selector {
    padding-top: 5px;
    padding-right: 20px;
}

/* POSTS */
.post_block {
    padding: 0;
    margin-bottom: 20px;
}

.post_block .media > .pull-left {
    margin-right: 20px;
}

span.media-heading {
    font-weight: bold;
}

.media {
    padding-top: 10px;
}

.comment_pic {
    background: url('/files/images/faq-list.png') no-repeat;
    width: 37px;
    height: 37px;
    margin-bottom: 5px;
    margin-left: 15px;
}

.comment_pic a {
    color: #FFFFFF;
    display: block;
    height: 39px;
    padding: 9px 0;
    text-align: center;
    font-weight: bold;
}

.comment_pic a:hover {
    text-decoration: none;
    color: #fff;
}

#page .comment_block ul.pagination li a { font-size: 1.1em; }

.readmore {
    font-weight: bold;
}
/*END POSTS*/

/*SIDEBAR*/
.sidebar {
    margin-top: 0;
}

.sidebar h2, #blog_element h2 {
    margin: 15px 10px 15px;
    font-size: 18px;
}

.sidebar .sidemenu_a h2 { color: #333; }
.mostsold h1 { color: #333; }

.sidebar h2 small { font-size: 14px; font-style: italic; }

.sidebar ul, #blog_element ul {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
}

.sidebar ul li, #blog_element ul li {
    list-style: none;
    border-top: 1px dashed #DFDFDF;
    padding: 5px 0;
}

ul li.blog_cats,
ul li.blog_archive,
ul li.tags {
    background: none;
    height: 55px;
    padding: 9px 0 15px 5px;
}

ul.blog-grid-styling {
    padding-left: 0;
    list-style: none;
}

span.sidebar-img {
    background-color: #FFFFFF;
    border: 4px solid #BABABA;
    border-radius: 10px;
    color: #000000;
    display: block;
    font-family: 'Pathway Gothic One',sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    padding: 0;
    text-align: center;
    width: 33px;
    margin-right: 10px;
}

ul li.blog_cats a,
ul li.blog_archive a,
ul li.tags a {
    font-weight: bold;
}

ul li.blog_cats a span,
ul li.blog_archive a span,
ul li.tags a span {
    font-weight: normal;
    font-style: italic;
}

.mostsold ul.steps,
.steps_menu div ul.steps {
    padding-left: 0;
}

a.sold_domain_img {
    text-decoration: none;
    float: left;
    margin-right: 10px;
}

li.sold_domain a {
    display: block;
    line-height: 38px;
}

.mostsold ul.steps li {
    list-style: none;
    padding-top: 10px;
}

ul li.homestep1,
ul li.homestep2,
ul li.homestep3 {
    padding: 9px 0 5px 0px;
    list-style-type: none;
}

ul li.homestep1 a,
ul li.homestep2 a,
ul li.homestep3 a {
    display: block;
    padding-top: 8px;
    font-weight: bold;
    text-decoration: underline;
}

ul li.homestep1 a {
    color: #C7CBB0;
}
ul li.homestep2 a {
    color: #B2B611;
}
ul li.homestep3 a {
    color: #9B998E;
}

ul li.homestep1 img,
ul li.homestep2 img,
ul li.homestep3 img {
    margin-right: 15px;
}

.sidebar ul li a:hover {
    text-decoration: none;
}

.sidebar a.on {
    font-weight: bold;
}

.sidemenu_a {
    margin-bottom: 30px;
}

.sidemenu_b {
    margin-bottom: 30px;
}

.sidemenu_c {
    margin-bottom: 30px;
}

.blockmenu_d {
    border: 2px solid #dfdfdf;
    background: #f6f6f6; /* Old browsers */
    background: -moz-linear-gradient(top, #f6f6f6 0%, #e6e6e8 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f6f6f6), color-stop(100%,#e6e6e8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f6f6f6 0%,#e6e6e8 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f6f6f6 0%,#e6e6e8 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f6f6f6 0%,#e6e6e8 100%); /* IE10+ */
    background: linear-gradient(to bottom, #f6f6f6 0%,#e6e6e8 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#e6e6e8',GradientType=0 ); /* IE6-9 */
}

.blockmenu_e {
    background-color: #fff;
    border: 2px solid #dfdfdf;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.blockmenu_f {
    background-color: #ededed;
    border-top: 2px solid #dfdfdf;
    border-left: 2px solid #dfdfdf;
    border-right: 2px solid #dfdfdf;
    margin-top: 10px;
    padding: 10px;
}

.blockmenu_g {
    background-color: #fff;
    border-bottom: 2px solid #dfdfdf;
    border-left: 2px solid #dfdfdf;
    border-right: 2px solid #dfdfdf;
}

.blockmenu_h {
    background: #f6f6f6; /* Old browsers */
    background: -moz-linear-gradient(top, #f6f6f6 0%, #ececec 100%, #7db9e8 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f6f6f6), color-stop(100%,#ececec), color-stop(100%,#7db9e8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f6f6f6 0%,#ececec 100%,#7db9e8 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f6f6f6 0%,#ececec 100%,#7db9e8 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f6f6f6 0%,#ececec 100%,#7db9e8 100%); /* IE10+ */
    background: linear-gradient(to bottom, #f6f6f6 0%,#ececec 100%,#7db9e8 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#7db9e8',GradientType=0 ); /* IE6-9 */
    border: 2px solid #dfdfdf;
    padding: 10px;
    height: 174px;
    position: relative;
    z-index: 9;
}

.blockmenu_h h2 { font-size: 24px; }

.sidemenu_c_inner {
    background-color: #d9dadc;
    padding: 8px;
}

h2.trade_title {
    color: #fff;
    padding: 6px 10px;
}

.trade_balance_header {
    padding: 4px 0;
}

.trade_balance_header span {
    line-height: 16px;
}

.sidebar .sidemenu_b ul li a:hover {
    text-decoration: none;
    color: #000;
}

.box_left ul li a.on { font-weight: bold; }
/*END SIDEBAR*/

/*SHOUTBOX*/
#shout_list {
    border: 1px solid #dfdfdf;
    background-color: #f6f6f6;
    padding: 20px 8px 30px;
    overflow: hidden;
}

#shout_list div p {
    margin: 0 0 10px;
}

form#add_shout_form label {
    font-size: 12px;
    padding-left: 5px;
}

form#add_shout_form .form-group {
    margin-bottom: 10px;
}

.shout_user {
    font-weight: bold;
}

a.shout_login {
    background: #cfceca; /* Old browsers */
    background: -moz-linear-gradient(top,  #cfceca 0%, #827f75 50%, #424033 51%, #424033 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cfceca), color-stop(50%,#827f75), color-stop(51%,#424033), color-stop(100%,#424033)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #cfceca 0%,#827f75 50%,#424033 51%,#424033 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #cfceca 0%,#827f75 50%,#424033 51%,#424033 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #cfceca 0%,#827f75 50%,#424033 51%,#424033 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #cfceca 0%,#827f75 50%,#424033 51%,#424033 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cfceca', endColorstr='#424033',GradientType=0 ); /* IE6-9 */
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857;
    margin-bottom: 0;
    padding: 4px 20px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    color: #fff;
    margin-top: 3px;
}

a.shout_login:hover { color: #fff; text-decoration: none; }
/*END SHOUTBOX*/

/*COIN BLOCK*/
.coin_block {
    margin-bottom: 10px;
}

.coin_block a {
    -webkit-box-shadow: inset 10px 10px 51px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 10px 10px 51px 0px rgba(0,0,0,0.75);
    box-shadow: inset 10px 10px 51px 0px rgba(0,0,0,0.75);
    border: 2px solid #6f6f71;
    border-radius: 10px;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.42857;
    margin-bottom: 0;
    padding: 1px 0;
    text-align: center;
    white-space: nowrap;
    color: #c6c7c9;
    text-decoration: none;
    background-color: #87888a;
}

.coin_block a:hover {
    -webkit-box-shadow: inset 10px 10px 51px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 10px 10px 51px 0px rgba(0,0,0,0.75);
    box-shadow: inset 10px 10px 51px 0px rgba(0,0,0,0.75);
    border: 2px solid #6f6f71;
    border-radius: 10px;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.42857;
    margin-bottom: 0;
    padding: 1px 0;
    text-align: center;
    white-space: nowrap;
    color: #c6c7c9;
    text-decoration: none;
    background-color: inherit;
}

.coin_block a.active {
    -webkit-box-shadow: inset 10px 10px 51px 0px rgba(199,199,199,1);
    -moz-box-shadow: inset 10px 10px 51px 0px rgba(199,199,199,1);
    box-shadow: inset 10px 10px 51px 0px rgba(199,199,199,1);
    color: #4da9b8;
    border: 2px solid #c7c7c7;
    background-color: #f3f3f3;
}

.coin_block a:hover {
    color: #c6c7c9;
    text-decoration: none;
}

.coin_block a:focus {
    color: #c6c7c9;
    text-decoration: none;
}
/*END COIN BLOCK*/

/*TRADE*/
.trade_coin_select { padding: 8px; }
form#sell_coins label, form#buy_coins label { text-align: left; }
a.trade_buy_calculate,
a.trade_buy,
a.trade_sell_calculate,
a.trade_sell,
.user-btn {
    background: #cfceca; /* Old browsers */
    background: -moz-linear-gradient(top,  #cfceca 0%, #827f75 50%, #424033 51%, #424033 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cfceca), color-stop(50%,#827f75), color-stop(51%,#424033), color-stop(100%,#424033)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #cfceca 0%,#827f75 50%,#424033 51%,#424033 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #cfceca 0%,#827f75 50%,#424033 51%,#424033 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #cfceca 0%,#827f75 50%,#424033 51%,#424033 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #cfceca 0%,#827f75 50%,#424033 51%,#424033 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cfceca', endColorstr='#424033',GradientType=0 ); /* IE6-9 */
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857;
    margin-bottom: 0;
    padding: 4px 15px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    border: none;
    color: #fff;
}

a.trade_calculate:hover {
    color: #fff;
    text-decoration: none;
}

.my_active_orders_headers, .total_orders_headers {
    padding: 8px 0;
    color: #000;
    font-weight: bold;
}

.my_active_orders_values, .total_orders_values {
    padding: 8px 0;
}

.my_active_orders_values .even div { background-color: #f1f1f1; padding: 3px 15px; margin-bottom: 3px; }
.my_active_orders_values .odd div { background-color: #fff; padding: 3px 15px; margin-bottom: 3px; }
.total_orders_values .even div { background-color: #f1f1f1; padding: 3px 15px; margin-bottom: 3px; }
.total_orders_values .odd div { background-color: #fff; padding: 3px 15px; margin-bottom: 3px; }

/*equal heights*/
[class*="col-height"]{
    margin-bottom: -99999px;
    padding-bottom: 99999px;
}

div.row.trade_values {
    overflow: hidden;
}
/*END TRADE*/

/*TWITTER FEED*/
.twitter_widget {
    background-color: #ededed;
    border-top: 2px solid #dfdfdf;
    border-left: 2px solid #dfdfdf;
    border-right: 2px solid #dfdfdf;
    margin-bottom: 10px;
    padding: 10px;
}

a.tweet_btn {
    background: url('../../files/images/tweet.png') no-repeat;
    width: 22px;
    height: 22px;
}

a.retweet_btn {
    background: url('../../files/images/re.png') no-repeat;
    width: 22px;
    height: 22px;
}

a.favo_btn {
    background: url('../../files/images/favo.png') no-repeat;
    width: 22px;
    height: 22px;
}

.retweet_buttons a:hover {
    background-position: 0 -22px;
}
/*END TWITTER*/

/*INFO BLOCK*/
#my_trade_block .sidemenu_b h2, #my_info_block .sidemenu_b h2 {
    font-size: 24px;
    margin: 10px 10px 15px;
    color: #fff;
    font-size: 24px;
}

#my_trade_block .sidemenu_b ul, #my_info_block .sidemenu_b ul {
    padding-left: 25px;
    padding-right: 15px;
    margin-bottom: 20px;
}

#my_trade_block .sidemenu_b ul li, #my_info_block .sidemenu_b ul li {
    color: #000;
}

ul#contact-btns { padding-left: 5px; }
ul#contact-btns li { list-style: none; line-height: 36px; }
ul#contact-btns li a { display: block; color: #000; text-decoration: none; }
ul#contact-btns li a:hover { color: #000; text-decoration: underline; }
ul#contact-btns li.contact-btn-env { background: url('../../files/images/enveloppe.png') no-repeat; height: 40px; padding-left: 60px; }
ul#contact-btns li.contact-btn-bird { background: url('../../files/images/tweet-bird.png') no-repeat; height: 40px; padding-left: 60px; }

/*END INFO BLOCK*/

/*DOWN ARROW*/
.downarrow {
    border-color: rgba(236, 236, 236, 0) #ececec rgba(236, 236, 236, 0) rgba(236, 236, 236, 0);
    border-style: solid;
    border-width: 40px;
    height: 0;
    right: 15px;
    position: absolute;
    top: 112px;
    width: 10px;
    z-index: 0;
    margin-bottom: 20px;
    background: #f6f6f6; /* Old browsers */
    background: -moz-linear-gradient(top, #f6f6f6 0%, #ececec 100%, #7db9e8 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f6f6f6), color-stop(100%,#ececec), color-stop(100%,#7db9e8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f6f6f6 0%,#ececec 100%,#7db9e8 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f6f6f6 0%,#ececec 100%,#7db9e8 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f6f6f6 0%,#ececec 100%,#7db9e8 100%); /* IE10+ */
    background: linear-gradient(to bottom, #f6f6f6 0%,#ececec 100%,#7db9e8 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#7db9e8',GradientType=0 ); /* IE6-9 */
}
/*DOWN ARROW*/

/*DIV*/
/*
.userdiv {
}
*/

/*COMMENT BLOCK*/
.comment_block {
    padding: 19px;
}

.comment_box {
    background: none;

}

h3#comments { color: #333; }

/*CALL OUT*/
.bs-callout-danger {
    background-color: #FDF7F7;
    border-color: #EED3D7;
}
.bs-callout {
    border-left: 3px solid #EEEEEE;
    margin: 20px 0;
    padding: 20px;
}
.bs-callout-danger h4 {
    color: #B94A48;
}
.bs-callout h4 {
    margin-bottom: 5px;
    margin-top: 0;
}
.bs-callout-info {
    background-color: #F4F8FA;
    border-color: #BCE8F1;
}
.bs-callout {
    border-left: 3px solid #EEEEEE;
    margin: 20px 0;
    padding: 20px;
}
.bs-callout-info h4 {
    color: #34789A;
}
.bs-callout h4 {
    margin-bottom: 5px;
    margin-top: 0;
}
/*END CALL OUT*/


/*IMAGES*/
img.img_left { float: left; margin: 0 10px 10px 0; }
img.img_right { float: right; margin: 0 0 10px 10px; }
img.left { float: left; margin: 0 10px 10px 0; }
img.right { float: right; margin: 0 0 10px 10px; }
#logo a img {
    width: auto;
}
table img, img { max-width: 100%; }
/*IMAGES END*/


/*VIDEO EMBED*/
video {
    max-width: 100%;
    height: auto;
}

iframe,
embed,
object {
    max-width: 100%;
}

/*WEBSHOP*/
#frontproducttable ul.thumbnails li, ul.thumbnails li { list-style: none; }
#frontproducttable ul { padding-left:0; }
#shop_categories { font-size: 12px; margin-top: 45px; }
#shop_categories .head { margin-bottom: 10px; }
#shop_categories ul {
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
#shop_categories a { cursor: pointer; }

.thumbnail p { padding: 0; }

.min_button,
.plus_button {
    background-color: #000000;
    float: left;
    height: 20px;
    line-height: 30px;
    margin-top: 7px;
    width: 15px;
}

/*ORDER*/
ul.order {
    background-color: #f1f1f1;
    padding: 0;
    list-style: none;
}
ul.order li {
    padding: 10px;
}
ul.order li.active {
    background-color: #ccc;
}
/*
ul.order li span {
}
ul.order li span.active_step { }
ul.order li span.step { }
*/

/*IDEAL MOLLIE*/
div.ideal_select select {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    color: #555555;
    display: block;
    font-size: 14px;
    height: 34px;
    line-height: 1.42857;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    margin-top: 15px;
}

.media span.bookmark_links {
    height: 60px;
}

.media span.bookmark_links a {
    display: block;
    text-align: center;
    height: 10px;
}

/*FORMS*/
.formblock_main form#custom_form div.input-group {
    display: inline;
}
.form-control:focus {
    border-color: #005580;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 194, 204, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 194, 204, 0.6);
}

/*CUSTOM PAGINATE BUTTONS*/
.pagination > li > a, .pagination > li > span {
    position: relative;
}
p.webshop_categories_pager { padding-right: 0; }

/*CUSTOM LINKS BLOCK*/
.custom_footer_block {
    padding: 40px 0 20px;
    background-color: #ccc;
}
.custom_footer_block div.footer_col {
    margin-bottom: 20px;
}
.custom_footer_block .foot_header {
    font-weight: bold;
    line-height: 24px;
}
.custom_footer_block .links a {
    margin-left: 5px;
    text-decoration: none;
}
.custom_footer_block .links a:hover {
    text-decoration: underline;
}
.custom_footer_block .links i {
    font-size: 10px;
    text-shadow: 1px -1px 1px rgba(0, 0, 0, 0.5);
}

/*404*/
.error-code {
    font-size: 150px;
    text-align: right;
}


/*COLLAPSE BUTTONS*/
#page .blog_cat_hideme, #page .blog_archive_hideme, #page .blog_tags_hideme { font-size: 12px; }

/*FEATURE BLOCKS*/
/*
.feature_block a img {
}
.feature_block a:hover img {
}
*/
.feature_block h3 {
    font-size: 1.5em;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}
.feature_block {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 10px 20px;
    text-align: center;
}
.feature_block a {
    display: block;
    text-align: center;
    text-decoration: none;
}
.feature_block img {
    text-align: center;
}
.feature_block p.text {
    text-align: center;
    padding: 6px 0;
}
.feature_block {
    border-radius: 8px;
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2);
}

/*TABLES*/
table {
    width: 100%;
    margin: 20px 0;
}

table tr td {
    border-top: 1px solid #DDDDDD;
    line-height: 20px;
    padding: 8px;
    text-align: left;
    vertical-align: top;
}

.product_cats tr td.child {
    padding-left: 25px !important;
}

.product_cats tr td.grandchild {
    padding-left: 50px !important;
}

.product_cats tr td.undergrandchild {
    padding-left: 75px !important;
}

/*collapse webshop categories div*/
#shop_categories .child {
    padding-left: 15px !important;
    margin-top: 5px;
    margin-bottom: 5px;
    /*padding-bottom: 5px;*/
    border-bottom: 1px dotted #ccc;
}

#shop_categories .grandchild {
    padding-left: 30px !important;
    margin-top: 5px;
    margin-bottom: 5px;
}

#shop_categories .undergrandchild {
    padding-left: 45px !important;
    margin-top: 5px;
    margin-bottom: 5px;
}

#shop_categories a { margin-bottom:0; padding: 3px 0; }
#shop_categories ul li ul  { padding-bottom: 5px; }

.logo_selector .thumbnail a img { max-height: 80px; }
.back_to_category_link { margin-bottom: 5px; display: block; }

.note {
    background: url("http://www.premiums.mobi/img/general/note_blanc.png") no-repeat scroll 0 0 / 100% auto rgba(0, 0, 0, 0);
    font-weight: bold;
    height: 320px;
    margin: 20px 0;
    max-width: 320px;
}

.note p {
    padding: 140px 60px 0; height: 145px;
}

ul#front_tab li > a {
    border: medium none;
}
ul#front_tab li a {
    background: url("http://www.premiums.mobi/img/general/front-tab.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    height: 11px;
    width: 11px;
}

@media screen and (max-width:500px) {
    /* Mobile styles */
    .checklist b, #theoriecursus b {
        font-size: 0.5em;
    }
    .checklist img, #theoriecursus img {
        width: 5%;
    }
    #theoriecursus img.img_right { width: 80%; }
}
@media screen and (min-width:501px) and (max-width:999px) {
    /* Tablet styles */
    .checklist b, #theoriecursus b {
        font-size: 0.8em;
    }
    .checklist img, #theoriecursus img {
        width: 5%;
    }
    #theoriecursus img.img_right { width: 80%; }
}
@media screen and (min-width:1000px) {
    /* Desktop styles */
    .checklist b, #theoriecursus b {
        font-size: 1.0em;
    }
    .checklist img, #theoriecursus img {
        width: 5%;
    }
    #theoriecursus img.img_right { width: 40%; }
}

/* MAPS */
#my_map{
    height: 250px;
    width: 99%;
    border: 1px solid #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.custom-google-maps .google-map,
.custom-google-maps .google-route-map { height: 250px; }

@media (max-width: 767px) {

    .modal-wrapper {
        position: absolute;
        top: 50px;
        left: 20%;
        z-index: 1050;
        width: 70%;
        margin-left: 0px;
        padding-bottom: 80px;
        overflow: visible;
    }

}

@media ( max-width : 480px) {
    .modal-wrapper {
        position: absolute;
        top: 50px;
        left: 10%;
        z-index: 1050;
        width: 80%;
        margin-left: 0px;
        padding-bottom: 80px;
        overflow: visible;
    }
}

#my_map img,
#my_route_map img {
    max-width: none;
    max-height: none;
}

/* NAV BREAKPOINTS */
@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}


/* Megamenu */
#top_mega_menu .ddown { float: right; padding-left: 5px; }
#top_mega_menu li.dropdown a:first-child { float: left; padding-right: 3px; }
#top_mega_menu .dropdown .dropdown-menu li a { float: none; padding: 3px 20px; }
.nav_description { text-decoration: none; }
.img_nav_wrap { margin: 3px 0; border-top: 1px solid #ccc; padding-top: 10px; padding-bottom: 10px; background-color: #f1f1f1; }
.img_nav_wrap img { margin-right: 10px; margin-bottom: 0; width: 20%; }
.img_nav_wrap a { padding: 3px 0 !important; color: #333333; }
.img_nav_wrap a:hover { text-decoration: none; color: #333333; }
#top_mega_menu li { list-style: none; }

#top_mega_menu li.dropdown-header:before {
    content:  "\f0da";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    padding-right: 0.5em;
}

.menu-large {
    position: static !important;
}
.megamenu{
    padding: 20px 0px;
    width:100%;
}
.megamenu> li > ul {
    padding: 0;
    margin: 0;
}
.megamenu> li > ul > li {
    list-style: none;
}
.megamenu> li > ul > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333333;
    white-space: normal;
}
.megamenu> li ul > li > a:hover,
.megamenu> li ul > li > a:focus {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
}
.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
    color: #999999;
}
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    cursor: not-allowed;
}
.megamenu.dropdown-header {
    color: #428bca;
    font-size: 18px;
}
.dropdown-header {
    padding: 3px 10px;
}

@media (max-width: 768px) {
    .megamenu{
        margin-left: 0 ;
        margin-right: 0 ;
    }
    .megamenu> li {
        margin-bottom: 30px;
    }
    .megamenu> li:last-child {
        margin-bottom: 0;
    }
    .megamenu.dropdown-header {
        padding: 3px 15px !important;

    }
    .navbar-nav .open .dropdown-menu .dropdown-header{
        color:#fff;
        padding: 5px 0;
    }

    .megamenu> li > ul > li > a {
        color: #ccc;
    }

    ul.language-menu {
        margin: 7.5px -15px !important;
    }

    a#mega-menu-1:before {
        content: "\f015";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        padding-right: 0.5em;
    }
}

/* Google maps */
.maps-block #my_map{
    height: 250px;
    width: 99%;
    border: 1px solid #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .maps-block .modal-wrapper {
        position: absolute;
        top: 50px;
        left: 20%;
        z-index: 1050;
        width: 70%;
        margin-left: 0px;
        padding-bottom: 80px;
        overflow: visible;
    }
}
@media ( max-width : 480px) {
    .maps-block .modal-wrapper {
        position: absolute;
        top: 50px;
        left: 10%;
        z-index: 1050;
        width: 80%;
        margin-left: 0px;
        padding-bottom: 80px;
        overflow: visible;
    }
}
.maps-block #gmaps #my_map img {
    max-width: none;
    max-height: none;
}
.maps_block #my_route_map img, #my_map img { max-width: none; max-height: none; }

.custom-google-maps .google-map,
.custom-google-maps .google-route-map { height: 240px; }

/* DEMO ELEMENTS */

.demo { position: relative; }
.demo .demo-content { opacity: 0.5; }
.demo.preview .demo-content { opacity: 1; }
.demo .demo-overlay { position: absolute; top: 0; right: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; }
.demo.preview .demo-overlay { width: auto; height: auto; }
.demo .demo-options { padding: 60px; background-color: rgba(75, 182, 174, 0.5); font-size: 3em; opacity: 1; }
.demo.preview .demo-options { padding: 15px; font-size: 1.2em; opacity: 0.1; }
.demo.preview:hover .demo-options { opacity: 1; }
.demo .demo-options a { display: inline-block; width: 1.2em; height: 1.2em; line-height: 1.2em; text-align: center; background-color: #4BB6AE; color: #fff; }
.demo .demo-options a:hover,
.demo .demo-options a:focus,
.demo .demo-options a:active { background-color: #fff; color: #4BB6AE; }

/* CUSTOM LAYOUTS */
@import "../common/_flexgrid";
@import "../common/flexbox";

.row-flex .col-custom { padding: 0 15px; }

@import "../elements/_embed";
@import "../elements/_maps";
@import "../elements/filepicker";
@import "../elements/alertbox";
@import "../elements/circle-link";
@import "../elements/media-controller";
@import "../elements/phone-info-top";
@import "../common/blocks";
@import "../common/text";
@import "../pages/navigation";

@import "../common/colors";
$alertbox-sides: (top, bottom, left, right);

$alertbox-colors: (
    message: color('blue'),
    warning: color('yellow'),
    error: color('red'),
    success: color('green'),
    info: color('dark-blue')
);

.alertbox-container {
    position: absolute;
    padding: 5px 15px;
    max-width: 100%;
    pointer-events: none;
    z-index: 10;

    &.alertbox-container-fixed {
        position: fixed;
    }

    @each $alertbox-side in $alertbox-sides {
        &.alertbox-container-#{$alertbox-side} {
            #{$alertbox-side}: 0;
        }
        &.alertbox-container-#{$alertbox-side}side {
            #{$alertbox-side}: 100%;
        }
    }

    &.alertbox-container-main {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1600;
    }

    .alertbox {
        display: none;
        overflow: hidden;
        pointer-events: auto;
        @include user-select(none);

        &.in {
            display: block;
        }

        .alertbox-wrapper {
            padding: 0 0 10px;
        }

        .alertbox-content {
            background-color: color('dark-grey');
            color: color('white');
            padding: 10px;
            width: 600px;
            max-width: 100%;
            position: relative;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
            @include display-flex();

            .alertbox-body {
                @include flex(1 0 0);
            }

            .alertbox-icon {
                padding-right: 15px;
            }

            .alertbox-title {
                font-size: 1.4em;
            }

            button {
                svg {
                    width: 1em;
                    height: 1em;
                    color: currentColor;
                }
            }

            button.alertbox-dismiss {
                color: inherit;
                background-color: transparent;
                background-image: none;
                border: none;
                padding: 0;
                position: absolute;
                right: 10px;
                top: 10px;
                width: 1em;
                height: 1em;
                line-height: 1em;

                svg {
                    width: 1em;
                    height: 1em;
                }
            }

            .alertbox-buttons:not(:empty) {
                padding-top: 15px;
            }

            .alertbox-button {
                border: none;
                padding: 5px 8px;
                color: color('dark-grey');
                background-color: color('white');
            }
        }

        @each $alertbox-type, $alertbox-color in $alertbox-colors {
            &.alertbox-#{$alertbox-type} .alertbox-content {
                background-color: $alertbox-color;
                color: contrast-color($alertbox-color);

                .alertbox-button {
                    color: $alertbox-color;
                    background-color: contrast-color($alertbox-color);
                }

                a {
                    color: inherit;
                    font-weight: 700;
                    text-decoration: underline;
                }
            }
        }

        svg {
            fill: currentColor;
            width: 32px;
            height: 32px;
        }
    }
}

@use 'sass:math';
@import "../common/vendor-prefixes";
@import "../common/variables";

.navigation {
    .menu {
        @include display-flex();
        list-style: none;
        padding: 0;

        .menu-item {
            @include display-flex();
            position: relative;

            .nav-link {
                display: block;
                padding: spacing(small) spacing(default);

                &:nth-last-of-type(2) {
                    padding-right: math.div(spacing(default), 2);
                }
            }

            .nav-open {
                padding: spacing(small) spacing(default) spacing(small) math.div(spacing(default), 2);
            }

            &.menu-dropdown {
                > .submenu {
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    list-style: none;
                    padding: 0;
                    background-color: color('white');
                    border: 1px solid color('light-grey');
                    z-index: 10;

                    .submenu {
                        left: 100%;
                        top: 0;
                    }
                }

                &.open, &.hover {
                    > .submenu {
                        display: block;
                    }
                }
            }
        }
    }
}

.navigation-basic {
    .menu {
        display: block;
        list-style: none;
        padding: 10px 15px;

        .menu-item {
            .menu-label-container {
                @include display-flex();
                @include flex-wrap(wrap);
                @include align-items(stretch);
                > .menu-link {
                    @include display-flex();
                    @include flex(1 0 0);

                    > .menu-label {
                        @include flex(1 0 0);
                    }
                }
            }

            &.menu-dropdown {
                > .menu-sub {
                    display: block;
                    width: 100%;
                    list-style: none;
                    @include flex(1 0 0);
                }
            }
        }
    }
}

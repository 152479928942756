.phone-info-top-container {
    @if (variable-exists(layout-template) and $layout-template == 'advanced') {
        padding: 12px 0 0 15px;
    } @else {
        padding: 12px 0 0;
    }
}
.phone-info-top {
    font-size: 1.5em; color: #fff; text-decoration: none;
}

@import './colors';
@import './variables';

$flex-gutter: map-get($spacing, 'default') * 2;

:root {
    --flex-gutter: #{$flex-gutter};

    @each $color-name, $color-value in $colors {
        --dt-color-#{$color-name}: #{$color-value};
    }
}

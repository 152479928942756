@use 'sass:math';
@import "../common/vendor-prefixes";

$embeds: (
    "56.25%": (
        portrait: math.div(100%, 9) * 16,
        embed: (youtube, vimeo)
    )
);

@each $width, $config in $embeds {
    $embed-classes: ();
    $embed: map-get($config, embed);
    $portrait: map-get($config, portrait);
    @each $embed-class in $embed {
        $embed-classes: append($embed-classes, ".embed-#{$embed-class}", comma);
    }
    #{$embed-classes} {
        display: block;
        position: relative;
        background-color: #000;
        width: 100%;
        padding-bottom: #{$width};
        cursor: pointer;
        overflow: hidden;
        &.embed-portrait {
            padding-bottom: $portrait;
        }
        img,
        iframe,
        .play-button,
        .play-button::before {
            position: absolute;
        }
        img,
        .play-button {
            cursor: pointer;
        }
        img {
            width: 100%;
            top: 50%;
            left: 0;
            @include transform(translate(0, -50%));
        }
        iframe {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
        .play-button {
            display: block;
            background-color: #333;
            border-radius: 30px;
            width: 60px;
            height: 60px;
            opacity: 0.8;
            z-index: 1;
            &::before {
                content: '';
                border-style: solid;
                border-width: 15px 0 15px 26px;
                border-color: transparent transparent transparent #fff;
            }
        }
        &:hover .play-button {
            opacity: 1;
        }
        .play-button,
        .play-button::before {
            left: 50%;
            top: 50%;
            @include transform(translate(-50%, -50%));
        }
    }
}

.embed-youtube {
    .play-button {
        width: 90px;
        height: 60px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
        border-radius: 6px;
    }
    &:hover .play-button {
        background-color: #cc181e;
    }
}

img[data-lazy-load] {
    display: none;
}

.lazy-load-container {
    display: block;
    max-width: 100%;

    > span {
        display: block;
    }

    > canvas {
        display: initial;
    }
}
canvas.lazy-load-container {
    display: initial;
}

.media-timeline {
    width: 100%;
    height: 1.5em;
    padding: 0.5em;
}

.media-timeline-bar {
    width: 100%;
    height: 0.5em;
    background-color: color('lighter-grey');
    position: relative;
}

.media-timeline-progress {
    width: 0;
    height: 100%;
    background-color: color('yellow');
    position: absolute;
    z-index: 1;
}

.media-timeline-playhead {
    width: 1.5em;
    height: 1.5em;
    margin: -0.5em;
    position: absolute;
    left: 0;
    top: 0;
    background-color: color('light-grey');
    border-radius: 100%;
    z-index: 2;
}

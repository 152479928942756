@use 'sass:math';
@import "variables";
@import "colors";
@import "block/image";

$overflows: (
    scroll,
    hidden,
    auto,
    visible
);

.block-display {
    display: block;
}

.block-display-inline {
    display: inline-block;
}

a.block-display, a.block-display-inline, a.block-link-noline {
    text-decoration: none;
}

@each $max-size in $block-sizes {
    @for $size from 1 through $max-size {
        #{if($max-size == 12, '.block-width-#{$size},', '')}.block-width-#{$size}\/#{$max-size} {
            width: 100% * math.div($size, $max-size);
        }
        #{if($max-size == 12, '.block-width-max-#{$size},', '')}.block-width-max-#{$size}\/#{$max-size} {
            max-width: 100% * math.div($size, $max-size);
        }
    }
}

.block-width-full {
    width: 100%;
}

.block-width-max-full {
    width: 100%;
}

@each $size-name, $size in $sizes {
    @media (min-width: $size) {
        @each $max-size in $block-sizes {
            @for $size from 1 through $max-size {
                #{if($max-size == 12, '.block-width-#{$size-name}-#{$size},', '')}.block-width-#{$size-name}-#{$size}\/#{$max-size} {
                    width: 100% * math.div($size, $max-size);
                }
                #{if($max-size == 12, '.block-width-#{$size-name}-max-#{$size},', '')}.block-width-#{$size-name}-max-#{$size}\/#{$max-size} {
                    max-width: 100% * math.div($size, $max-size);
                }
            }
        }

        .block-width-#{$size-name}-full {
            width: 100%;
        }

        .block-width-#{$size-name}-max-full {
            width: 100%;
        }

        .block-width-#{$size-name}-auto {
            width: auto;
        }
        .block-width-#{$size-name}-max-auto {
            max-width: none;
        }
    }
}

.block-height-auto {
    min-height: 0;
    height: auto;
    max-height: none;
}

.block-height-full {
    height: 100%;
}

.block-height-max-full {
    max-height: 100%;
}

.block-border-none {
    border: none;
}

.block-container {
    position: relative;
}

.block-position-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.block-position-underlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.block-container-z {
    z-index: 1;
}

.block-position-overlay-border {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
}

.block-position-top-left {
    position: absolute;
    top: 0;
    left: 0;
}

.block-resizeable {
    resize: both;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
}

.block-position-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.block-position-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}

.block-position-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.block-position-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.block-position-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.block-position-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.block-position-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

.block-position-sticky {
    position: sticky;
}

.block-position-sticky-top {
    position: sticky;
    top: 0;
}

.block-position-sticky-bottom {
    position: sticky;
    bottom: 0;
}

.block-dropzone {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.block-dropshadow {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

@each $overflow in $overflows {
    .block-overflow-#{$overflow} {
        overflow: #{$overflow};
    }
    .block-overflow-#{$overflow}-x {
        overflow-x: #{$overflow};
    }
    .block-overflow-#{$overflow}-y {
        overflow-y: #{$overflow};
    }
}

@each $spacing-type, $spacing-property in $spacing-types {
    @each $spacing-name, $spacing-value in $spacing {
        #{if($spacing-name == default, '.block-' + $spacing-type + ',', '')}.block-#{$spacing-type}-#{$spacing-name} {
            #{$spacing-property}: $spacing-value;
        }
        @each $spacing-direction-name, $spacing-direction-props in $spacing-directions {
            #{if($spacing-name == default, '.block-' + $spacing-type + '-' + $spacing-direction-name + ',', '')}.block-#{$spacing-type}-#{$spacing-name}-#{$spacing-direction-name} {
                @each $spacing-direction-prop in $spacing-direction-props {
                    #{$spacing-property}-#{$spacing-direction-prop}: $spacing-value;
                }
            }
        }
    }
}

@each $spacing-name, $spacing-value in $spacing {
    #{if($spacing-name == default, '.block-pull,', '')}.block-pull-#{$spacing-name} {
        margin: -$spacing-value;
    }
    @each $spacing-direction-name, $spacing-direction-props in $spacing-directions {
        #{if($spacing-name == default, '.block-pull-' + $spacing-direction-name + ',', '')}.block-pull-#{$spacing-name}-#{$spacing-direction-name} {
            @each $spacing-direction-prop in $spacing-direction-props {
                margin-#{$spacing-direction-prop}: -$spacing-value;
            }
        }
    }
}

@mixin block-color-background($color-name, $color-value, $gradient-modifier: 0%) {
    .block-#{'' + $color-name} {
        background-color: $color-value;
        color: contrast-color($color-value);
        @if ($gradient-modifier != 0%) {
            background-image: linear-gradient($color-value, hover-color($color-value, $gradient-modifier));
        }

        &.block-color-links a {
            color: contrast-color($color-value);

            &:hover, &:focus {
                color: contrast-color($color-value, color('light-grey'), color('dark-grey'));
            }
        }
    }
    a.block-#{'' + $color-name} {
        background-color: $color-value;
        color: contrast-color($color-value);
        @if ($gradient-modifier != 0%) {
            background-image: linear-gradient($color-value, hover-color($color-value, $gradient-modifier));
        }

        &:hover, &:focus {
            color: contrast-color($color-value, color('light-grey'), color('dark-grey'));
        }

        &.block-hover-color:hover {
            background-color: hover-color($color-value);
            color: contrast-color(hover-color($color-value));
            @if ($gradient-modifier != 0%) {
                background-image: linear-gradient(hover-color($color-value), hover-color($color-value, 5% + $gradient-modifier));
            }
        }

        &.block-active-color {
            background-color: hover-color($color-value, 10%);
            color: contrast-color(hover-color($color-value, 10%));
            @if ($gradient-modifier != 0%) {
                background-image: linear-gradient(hover-color($color-value, 10%), hover-color($color-value, 10% + $gradient-modifier));
            }
            &.block-hover-color:hover {
                background-color: hover-color($color-value, 15%);
                color: contrast-color(hover-color($color-value, 15%));
                @if ($gradient-modifier != 0%) {
                    background-image: linear-gradient(hover-color($color-value, 15%), hover-color($color-value, 15% + $gradient-modifier));
                }
            }
        }

        &.block-link {
            position: relative;
        }

        .block-link-image {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        @each $spacing-name, $spacing-value in $spacing {
            @if($spacing-value > 0) {
                #{'&' + if($spacing-name == default, '.block-pd,&', '')}.block-pd-#{$spacing-name} {
                    .block-link-image {
                        right: $spacing-value;
                        bottom: $spacing-value;
                    }
                }
                @each $spacing-direction-name, $spacing-direction-props in $spacing-directions {
                    @if($spacing-direction-name != 'left' and $spacing-direction-name != 'up') {
                        #{'&' + if($spacing-name == default, '.block-pd-' + $spacing-direction-name + ',&', '')}.block-pd-#{$spacing-name}-#{$spacing-direction-name} {
                            .block-link-image {
                                @if($spacing-direction-name == 'right' or $spacing-direction-name == 'x') {
                                    right: $spacing-value;
                                }
                                @if($spacing-direction-name == 'down' or $spacing-direction-name == 'y') {
                                    bottom: $spacing-value;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin block-color-border($color-name, $color-value) {
    @each $border-style in $border-styles {
        #{if($border-style == solid, '.block-border-' + $color-name + ',', '')}.block-border-#{$border-style}-#{'' + $color-name} {
            border: 1px $border-style $color-value;
        }
        @each $spacing-direction-name, $spacing-direction-props in $spacing-directions {
            #{if($border-style == solid, '.block-border-' + $color-name + '-' + $spacing-direction-name + ',', '')}.block-border-#{$border-style}-#{'' + $color-name}-#{$spacing-direction-name} {
                @each $spacing-direction-prop in $spacing-direction-props {
                    border-#{$spacing-direction-prop}: 1px $border-style $color-value;
                }
            }
        }
    }

    @each $spacing-direction-name, $spacing-direction-props in $spacing-directions {
        .block-border-#{'' + $color-name}-#{$spacing-direction-name} {
            @each $spacing-direction-prop in $spacing-direction-props {
                border-#{$spacing-direction-prop}: 1px solid $color-value;
            }
        }
    }
}

@mixin block-color($color-name, $color-value) {
    @include block-color-background($color-name, $color-value);
    @include block-color-background(#{$color-name}-gradient, $color-value, 10%);

    @include block-color-border($color-name, $color-value);
    @include block-color-border(#{$color-name}-hl, hover-color($color-value, 10%));
}

@if ($settings-include-block-colors) {
    @each $color-name, $color-value in $colors {
        @include block-color($color-name, $color-value);
    }
}

@each $cursor in $cursors {
    .block-cursor-#{$cursor} {
        cursor: $cursor;
    }
}

.block-opacity-25 {
    opacity: 0.25;
}

.block-opacity-50 {
    opacity: 0.5;
}

.block-opacity-75 {
    opacity: 0.75;
}

@each $aspect-ratio in $aspect-ratios {
    $aspect-ratio-width: nth($aspect-ratio, 1);
    $aspect-ratio-height: nth($aspect-ratio, 2);

    .block-aspect-ratio-#{$aspect-ratio-width}x#{$aspect-ratio-height} {
        aspect-ratio: #{$aspect-ratio-width}/#{$aspect-ratio-height};
    }
}

.block-z-m1 {
    z-index: -1;
}

$z: 0;
@while $z <= 100 {
    $real-z: $z * 10;
    .block-z-#{$real-z} {
        z-index: $real-z;
    }
    $z: $z + 1;
}

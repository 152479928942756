@import "variables";
@import "colors";
@import "vendor-prefixes";
@import "string";

.img-left { display: block; margin-right: auto; }
.img-center { display: block; margin: 0 auto; }
.img-right { display: block; margin-left: auto; }
.bg-primary { color: #fff; background-color: #337ab7; }
a.bg-primary:hover { background-color: #286090; }
.bg-success { background-color: #dff0d8; }
a.bg-success:hover { background-color: #c1e2b3; }
.bg-info { background-color: #d9edf7; }
a.bg-info:hover { background-color: #afd9ee; }
.bg-warning { background-color: #fcf8e3; }
a.bg-warning:hover { background-color: #f7ecb5; }
.bg-danger { background-color: #f2dede; }
a.bg-danger:hover { background-color: #e4b9b9; }
.container-fluid { padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }
.img-thumbnail-dark {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    line-height: 20/14;
    background-color: #000;
    border: 1px solid #222;
    border-radius: 4px;
    @include transition(all .2s ease-in-out);
}

.modal {
    &.modal-dynamic {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
        @include flex-direction(column);
        &.modal-dynamic-centered {
            @include align-items(center);
            @include justify-content(center);
            @include align-content(center);
        }
    }

    .modal-bar {
        @include display-flex();
        left: -10px;
        right: -10px;
        position: absolute;
        z-index: 1;

        &.modal-header-bar {
            top: -10px;
        }
        &.modal-footer-bar {
            bottom: -10px;
        }
        .modal-bar-title {
            color: color('white');
            text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 2px 2px 4px rgba(0, 0, 0, 0.25);
            padding-right: 5px;
            font-weight: bold;
            @include flex(1 0 0);
        }
        .modal-bar-spacer {
            @include flex(1 0 0);
        }
        .modal-bar-btns {
            text-align: center;
        }
    }

    .modal-control {
        .modal-control-left, .modal-control-right {
            position: absolute;
            top: 0;
            bottom: 0;
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            @include align-content(center);
        }
        .modal-control-left {
            left: -10px;
        }
        .modal-control-right {
            right: -10px;
        }
    }

    .modal-dialog {
        &.modal-crop {
            margin: 0 auto;
            padding: 30px 0;
            max-height: 100vh;
            @include display-flex();
            @include align-items(stretch);
            &.modal-padded {
                padding-left: 30px;
                padding-right: 30px;
            }
            .modal-content {
                display: flex;
                flex-direction: column;
                align-content: stretch;
                width: 100%;
                .modal-body {
                    overflow: hidden;
                }
            }
        }
        &.modal-contain {
            margin: 0 auto;
            padding: 30px 0;
            max-height: 100%;
            @include display-flex();
            @include align-items(stretch);

            &.modal-contain-vh {
                max-height: 100vh;
            }

            &.modal-padded {
                padding-left: 30px;
                padding-right: 30px;
            }
            .modal-content {
                display: flex;
                flex-direction: column;
                align-content: stretch;
                width: 100%;
                .modal-body {
                    overflow: auto;
                    &[data-simplebar="init"] {
                        overflow: hidden;
                        padding: 0;
                        .simplebar-content {
                            padding: 15px;
                            &[style*="padding-bottom: 17px"] {
                                padding-bottom: 32px !important;
                            }
                        }
                    }
                }
            }
        }
        &.modal-max {
            width: auto;
            .modal-content {
                width: auto;
                margin: 0 auto;
                max-width: 100%;
            }
        }
    }
}

.modal-header-btns {
    float: right;

    .modal-header & {
        margin-top: -2px;
    }

    .modal-header-btn {
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        color: inherit;
        text-shadow: 0 1px 0 #fff;
        filter: alpha(opacity=20);
        opacity: 0.2;
        margin-left: 5px;
    }

    button.modal-header-btn {
        -webkit-appearance: none;
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;

        &:focus, &:hover {
            color: inherit;
            text-decoration: none;
            cursor: pointer;
            filter: alpha(opacity=50);
            opacity: 0.5;
        }
    }
}

.modal-resize {
    &:not(.active) {
        display: none;
    }

    &.in .modal-resize-inactive {
        display: none;
    }
    &:not(.in) .modal-resize-active {
        display: none;
    }
}

@mixin button-properties($color-value, $border-change: 0%, $gradient-modifier: 0%) {
    background-color: $color-value;
    border-color: hover-color($color-value, $border-change);
    @if ($gradient-modifier != 0%) {
        background-image: linear-gradient($color-value, hover-color($color-value, $gradient-modifier));
    }
    color: contrast-color($color-value, #333);
    &:hover, &:focus {
        background-color: hover-color($color-value, 10%);
        border-color: hover-color($color-value, 10% + $border-change);
        @if ($gradient-modifier != 0%) {
            background-image: linear-gradient(hover-color($color-value, 10%), hover-color($color-value, 10% + $gradient-modifier));
        }
        color: contrast-color($color-value, #333);
    }
    &:active, &.active, &:hover:active, &.active:hover, &:focus:active, &.active:focus {
        background-color: hover-color($color-value, 20%);
        border-color: hover-color($color-value, 20% + $border-change);
        @if ($gradient-modifier != 0%) {
            background-image: linear-gradient(hover-color($color-value, 20%), hover-color($color-value, 20% + $gradient-modifier));
        }
        color: contrast-color($color-value, #333);
    }
}

@mixin set-button($button-name, $color-value, $border-change: 0%, $gradient-modifier: 0%) {
    .#{$button-name} {
        @include button-properties($color-value, $border-change, $gradient-modifier);
    }
}

@mixin set-text($text-name, $color-value) {
    .#{$text-name} {
        color: $color-value;

        > a {
            color: $color-value;
            &:hover,
            &:focus,
            &:active {
                color: lighten($color-value, 10%);
            }
        }
    }

    a.#{$text-name} {
        color: $color-value;
        &:hover,
        &:focus,
        &:active {
            color: lighten($color-value, 10%);
        }
    }
}

@mixin bootstrap-colors($color-list) {
    @if ($settings-include-text-colors) {
        @each $color-name, $color-value in $color-list {
            @if (type-of($color-value) == color) {
                @include set-text(text-color-#{'' + $color-name}, $color-value);
            }
        }
    }

    @if ($settings-include-button-colors) {
        @each $color-name, $color-value in $color-list {
            @if (type-of($color-value) == color) {
                @include set-button(btn-color-#{"" + $color-name}, $color-value);
                @include set-button(btn-color-#{"" + $color-name}-bordered, $color-value, 10%);
                @include set-button(btn-color-#{"" + $color-name}-gradient, $color-value, 10%, 10%);
                @include set-button(btn-color-#{"" + $color-name + "-disabled:disabled"}, $color-value);
            }
        }
    }
}

@include bootstrap-colors($colors);

.btn {
    &.btn-circle {
        width: 2em;
        height: 2em;
        line-height: 2em;
        border-radius: 1em;
        text-align: center;
        padding: 0;

        &.btn-xs {
            font-size: 0.5em;
        }

        &.btn-is {
            font-size: 0.625em;
        }

        &.btn-sm {
            font-size: 0.75em;
        }

        &.btn-im {
            font-size: 0.875em;
        }
    }

    .btn-bg-inherit {
        border: none;
        background-color: inherit;
    }
}

:focus.sr-only-focusable {
    width: auto;
    height: auto;
    margin: 0;
    overflow: auto;
    clip: auto;
}

@mixin set-text-size() {
    $i: map-get($text-size, min);
    @while $i <= map-get($text-size, max) {
        $i-big: zerofill($i * 100, 3);
        .text-size-#{$i-big} {
            font-size: $i * 1em;
        }
        $i: $i + map-get($text-size, step);
    }
}

@include set-text-size();

.container-inline-wrapper {
    container-name: container-inline;
    container-type: inline-size;
}

.container-inline {
    width: 100%;
}

@container container-inline (min-width: 768px) {
    .container-inline {
        width: 750px;
    }
}

@container container-inline (min-width: 992px) {
    .container-inline {
        width: 970px;
    }
}

@container container-inline (min-width: 1200px) {
    .container-inline {
        width: 1170px;
    }
}

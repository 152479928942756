.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}
.flex-row-reverse {
    flex-direction: row-reverse;
}
.flex-col {
    flex-direction: column;
}
.flex-col-reverse {
    flex-direction: column-reverse;
}

.flex-wrap {
    flex-wrap: wrap;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}
.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-1 {
    flex: 1 1 0;
}
.flex-grow {
    flex: 1 0 0;
}
.flex-shrink {
    flex: 0 1 100%;
}
.flex-auto {
    flex: 1 1 auto;
}
.flex-initial {
    flex: 0 1 auto;
}
.flex-none {
    flex: none;
}

.gap-space {
    gap: 4px;
}

@each $spacing-name, $spacing-value in $spacing {
    .gap#{if($spacing-name == default, ',.gap', '')}-#{$spacing-name} {
        gap: $spacing-value * 2;
    }
}

.gutter {
    margin-left: calc(var(--flex-gutter) * -0.5);
    margin-right: calc(var(--flex-gutter) * -0.5);
}

@each $spacing-name, $spacing-value in $spacing {
    .gutter-#{$spacing-name} {
        --flex-gutter: $spacing-value * 2;
    }
}

.gutter > * {
    padding-left: calc(var(--flex-gutter) * 0.5);
    padding-right: calc(var(--flex-gutter) * 0.5);
}

@each $align-name, $align-value in $align-content {
    .flex-align-content-#{$align-name} {
        -webkit-align-content: $align-name;
        align-content: $align-name;
    }
}

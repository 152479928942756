$sizes: 10;

object[data$=".svg"].icon, svg.icon {
    fill: currentColor;
    width: 1em;
    height: 1em;

    &.icon-x1h {
        width: 1.5em;
        height: 1.5em;
    }

    @for $size from 2 to $sizes {
        &.icon-x#{$size} {
            width: $size * 1em;
            height: $size * 1em;
        }

        &.icon-x#{$size}h {
            width: ($size + 0.5) * 1em;
            height: ($size + 0.5) * 1em;
        }
    }
}

.strike-icon {
    clip-path: polygon(-20% -20%, 120% -20%, 120% 120%, -20% -20%, -20% 0%, 120% 140%, -20% 140%);
    display: inline-block;
    position: relative;

    &::after {
        content: '';
        width: 151%;
        height: 20%;
        background-color: currentColor;
        display: block;
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform-origin: center bottom;
        transform: translateX(-50%) rotateZ(45deg);
        border-radius: 9999px;
    }
}

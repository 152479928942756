@use 'sass:math';

.block-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.block-image-contain {
    background-size: contain;
}

.block-image-tiled-contain {
    background-size: contain;
    background-repeat: repeat;
}

@each $aspect-ratio in $aspect-ratios {
    $aspect-ratio-width: nth($aspect-ratio, 1);
    $aspect-ratio-height: nth($aspect-ratio, 2);
    $aspect-ratio-padding: math.div(100%, $aspect-ratio-width) * $aspect-ratio-height;

    .block-image-#{$aspect-ratio-width}x#{$aspect-ratio-height} {
        padding-bottom: $aspect-ratio-padding;
    }
}

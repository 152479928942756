.default-overlay-style {
    position: fixed;
    z-index: 10;
    background: black;
    display: block;
    opacity: .75;
    filter: alpha(opacity=75);
    width: 100%;
    height: 100%;
	top: 0;
    left: 0;
}

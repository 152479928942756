@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}
@mixin transform-origin($transform-origin) {
    -webkit-transform-origin: $transform-origin;
    -moz-transform-origin: $transform-origin;
    -ms-transform-origin: $transform-origin;
    -o-transform-origin: $transform-origin;
    transform-origin: $transform-origin;
}

@mixin transition($transition) {
    -webkit-transition: #{$transition};
    -moz-transition: #{$transition};
    -ms-transition: #{$transition};
    -o-transition: #{$transition};
    transition: #{$transition};
}

@mixin transition-property($transition-property) {
    -webkit-transition-property: #{$transition-property};
    -moz-transition-property: #{$transition-property};
    -ms-transition-property: #{$transition-property};
    -o-transition-property: #{$transition-property};
    transition-property: #{$transition-property};
}

@mixin transition-duration($transition-duration) {
    -webkit-transition-duration: $transition-duration;
    -moz-transition-duration: $transition-duration;
    -ms-transition-duration: $transition-duration;
    -o-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}

@mixin transition-timing-function($transition-timing-function) {
    -webkit-transition-timing-function: $transition-timing-function;
    -moz-transition-timing-function: $transition-timing-function;
    -ms-transition-timing-function: $transition-timing-function;
    -o-transition-timing-function: $transition-timing-function;
    transition-timing-function: $transition-timing-function;
}

@mixin display-flex() {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin flex($flex) {
    -webkit-box-flex: 0;
    -webkit-flex: $flex;
    -ms-flex: $flex;
    flex: $flex;
}

@mixin flex-direction($flex-direction) {
    -webkit-flex-direction: $flex-direction;
    -ms-flex-direction: $flex-direction;
    flex-direction: $flex-direction;
}

@mixin flex-wrap($flex-wrap) {
    -webkit-flex-wrap: $flex-wrap;
    -ms-flex-wrap: $flex-wrap;
    flex-wrap: $flex-wrap;
}

@mixin flex-basis($flex-basis) {
    -webkit-flex-basis: $flex-basis;
    -ms-flex-preferred-size: $flex-basis;
    flex-basis: $flex-basis;
}

@mixin flex-grow($flex-grow) {
    -webkit-box-flex: $flex-grow;
    -webkit-flex-grow: $flex-grow;
    -ms-flex-positive: $flex-grow;
    flex-grow: $flex-grow;
}

@mixin align-items($align-items) {
    -webkit-align-items: $align-items;
    align-items: $align-items;
}

@mixin align-content($align-content) {
    -webkit-align-content: $align-content;
    align-content: $align-content;
}

@mixin justify-content($justify-content) {
    -webkit-justify-content: $justify-content;
    justify-content: $justify-content;
}

@mixin align-self($align-self) {
    -webkit-align-self: $align-self;
    align-self: $align-self;
}

@mixin user-select($user-select) {
    -webkit-touch-callout: $user-select;
    -webkit-user-select: $user-select;
    -khtml-user-select: $user-select;
    -moz-user-select: $user-select;
    -ms-user-select: $user-select;
    user-select: $user-select;
}

.btn.text-left { text-align: left; }
.btn.text-center { text-align: center; }
.btn.text-right { text-align: right; }

.text-justify { text-align: justify; }
.text-justify-left { text-align: justify; text-align-last: left; }
.text-justify-center { text-align: justify; text-align-last: center; }
.text-justify-right { text-align: justify; text-align-last: right; }
.text-nowrap { white-space: nowrap; }
.text-lowercase { text-transform: lowercase; }
.text-uppercase { text-transform: uppercase; }
.text-capitalize { text-transform: capitalize; }
.text-code { font-family: monospace; white-space: pre; }

.text-font-serif { font-family: serif; }
.text-font-sans-serif { font-family: sans-serif; }
.text-font-monospace { font-family: monospace; }
.text-font-cursive { font-family: cursive; }
.text-font-fantasy { font-family: fantasy; }

.text-overflow { overflow: hidden; white-space: nowrap; }
.text-overflow-ellipsis { text-overflow: ellipsis; }

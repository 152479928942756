/* FilePicker */
[data-input="filepicker"], [data-input="dropzone"] { position: relative; }
[data-input="filepicker"] input[type="file"], [data-input="dropzone"] input[type="file"] { position: absolute; width: 100%; height: 100%; top: 0; left: 0; opacity: 0; }

/* Dropzone */
.dropzone, .dropzone-highlight { border: 2px dashed #ccc; padding: 15px; }
.dropzone-highlight { border-color: #4f9fcf; }

ul.file-list { display: inline-block; padding: 0; }
ul.file-list > li { display: block; }

.has-warning .dropzone {
  color: #c09853;
  border-color: #c09853;
}

.has-warning .dropzone-highlight {
  color: #a47e3c;
  border-color: #a47e3c;
  background-color: #fcf8e3;
}

.has-error .dropzone {
  color: #b94a48;
  border-color: #b94a48;
}

.has-error .dropzone-highlight {
  color: #953b39;
  border-color: #953b39;
  background-color: #f2dede;
}

.has-success .dropzone {
  color: #468847;
  border-color: #468847;
}

.has-success .dropzone-highlight {
  color: #356635;
  border-color: #356635;
  background-color: #dff0d8;
}
